import Vue from 'vue'
import Router from 'vue-router'

const Home = () => import('@/views/Home')
const PlanList = () => import('@/views/pages/PlansList')
const paymentFail = () => import('@/views/pages/paymentFail')
const PaymentSuccess = () => import('@/views/pages/PaymentSuccess')
const PlanHistory = () => import('@/views/pages/PlanHistory')
const PlanData = () => import('@/views/pages/PlanData')
const PlansData = () => import('@/views/pages/PlansData')
const Terms_conditions = () => import('@/views/Terms_conditions')
const PrivacyPolicy = () => import('@/views/PrivacyPolicy')
const Cart = () => import('@/views/pages/Cart')
const Profile = () => import('@/views/users/Profile')
const Dashboard = () => import('@/views/Dashboard')
const ForgotPassword = () => import('@/views/pages/ForgotPassword')
const ForgotPasswordUp = () => import('@/views/pages/ForgotPasswordUp')
const Verification = () => import('@/views/pages/Verification')
Vue.use(Router)
export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      name: 'HomePage',
      component: Home,
    },
    {
      path: '/home',
      name: 'HomePage',
      component: Home,
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: '/plans',
      name: 'PlanList',
      component: PlanList,
    },

    {
      path: '/history',
      name: 'PlanHistory',
      component: PlanHistory,
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
    },
    {
      path: '/termsconditions',
      name: 'Terms&conditions',
      component: Terms_conditions,
    }, {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
    },
    {
      path: '/plandata/:id',
      name: 'PlanData',
      component: PlanData,
    },
    {
      path: '/plansdata/:id',
      name: 'PlansData',
      component: PlansData,
    },
    {
      path: '/cart',
      name: 'Cart',
      component: Cart,
    },
    {
      path: '/paymentfail',
      name: 'paymentFail',
      component: paymentFail,
    },
    {
      path: '/paymentsuccess',
      name: 'PaymentSuccess',
      component: PaymentSuccess,
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: ForgotPassword,
    }, {
      path: '/forgotpassword/:id',
      name: 'ForgotPasswordUp',
      component: ForgotPasswordUp,
    }, {
      path: '/verification',
      name: 'Verification',
      component: Verification,
    },
  ]

}


